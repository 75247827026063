import { ReactElement } from 'react';
import styles from './LiveTextBroadcastCard.module.scss';

type LiveTextBroadcastCardProps = {
  time: string;
  text: string;
};

function LiveTextBroadcastCard({
  time,
  text,
}: LiveTextBroadcastCardProps): ReactElement {
  return (
    <div className={styles.liveTextBroadcastCard}>
      <span className={styles.liveTextBroadcastCardTime}>{time}</span>
      <span className={styles.liveTextBroadcastCardText}>{text}</span>
    </div>
  );
}

export default LiveTextBroadcastCard;
